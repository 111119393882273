import { useEffect, useState } from 'react';

const useStaleFree = (callback) => {
    const [call, setCall] = useState(false);
    useEffect(() => {
        if (call) {
            callback();
            setCall(false);
        }
    }, [call, callback, setCall]);
    // when useStaleFree() is called, it sets call to be true, then execute callback
    return () => setCall(true);
};

export default useStaleFree;
