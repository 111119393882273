import React from 'react';
import NumberFormat from 'react-number-format';

const SYMBOL = '$';

const AmountFormat = ({
    value,
    renderText = (value) => SYMBOL + ' ' + value,
    ...props
}) => {
    if (isNaN(parseInt(value))) return renderText('---');

    return (
        <NumberFormat
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            value={value}
            renderText={renderText}
            {...props}
        />
    );
};

export default AmountFormat;
